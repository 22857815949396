











import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";

@Component({
})

export default class Meetings extends Vue {
    @Prop({ required: true })
    private pageTitle: string;

    public iframeUrl: string = "";
    public sheetUrl: string = "";

    public async created() {
        axios.get("/sheets/progress-monitoring-tool").then((res) => {
            this.iframeUrl = res.data.iframe_url;
            this.sheetUrl = res.data.sheet_url;
        });
    }
}
